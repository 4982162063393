import { Link } from "react-router-dom";
import { FooterContentProps } from "@ultracommerce/react-storefront/global/src/components/Footer/FooterContent";
import { useElementContext } from "@ultracommerce/react-storefront/global";

export const FooterStyleTwo = ({ logo, theme, ribbonMenu, formElement, contentStack }: FooterContentProps) => {
  const {
    CommonModule: { PoweredBy, ContentStackMenu },
  } = useElementContext();
 
    return (
      <>
        <div className="container flex-column align-items-lg-end align-items-md-start">
          <div className="row col-12 py-4">
            <div className="col-lg-4 col-md-4 col-sm-12 footer-brandImage flex-sm-row flex-md-column">
              {theme?.footerLogoTarget !== "None" && (
                <Link className="col-md-3 col-sm-12 d-flex justify-content-start" to="/">
                  <span className="navbar-brand d-block">
                    <img
                      src={`${logo?.trim()}`}
                      alt=""
                      className="img-fluid navbar-brandLogo"
                      style={{
                        maxHeight: "60px",
                        minWidth: "150px",
                        marginBottom: "1em",
                      }}
                    />
                  </span>
                </Link>
              )}
              <div className="col-md-3 col-sm-12 pt-4" dangerouslySetInnerHTML={{ __html: theme.FooterAddress }} />
            </div>
            <div className="d-flex flex-column col-sm-12 col-md-8 col-lg-8 align-items-lg-end align-items-md-start">
              <div className="row w-100 justify-content-around flex-wrap flex-lg-nowrap">
                <ContentStackMenu contentStack={contentStack} />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-ribbon footer-ribbon-two p-0 flex-wrap">
        <div className="container py-4 px-0 d-flex justify-content-between align-items-center align-items-sm-start flex-md-row flex-column">
          {theme.copyRight && <p className="m-0 me-2 align-self-center">{theme.copyRight}</p>}
          {ribbonMenu}
          <PoweredBy styleList={{ height: "25px", width: "fit-content" }} theme={theme} />
        </div>
        </div>
      </>
    );
  };
  